.gallery-image {
    width: 100%;
}

.photo-wrapper .photo-title {
    padding: 5px;
    background-color: white;
}

.photo-wrapper {
    overflow: hidden;
}

.photo-wrapper:hover {
    overflow: visible;
}

.photo-wrapper {
    background-color: white;
}

.photo-frame {
    position: absolute;
    transition: all ease 500ms;
    box-shadow: 0 0 0 0.01px white;
}
    .photo-frame:hover {
        box-shadow: 0 0 0 10px #ffffff;
        z-index: 100;
    }

#image-grid {
    margin-top: 10px;
}

.yarl__container {
    background-color: rgba(255, 255, 255, 1);
}

#tagFilter {
    background-color: rgb(246, 246, 246);
    font-family: 'PT Sans';
    font-size: 12px;
    border-top-color: rgb(236, 236, 236);
    border-top-style: solid;
    border-top-width: 1px;
    margin-bottom: 7px;
    overflow: hidden;
    height: 23px;
}

    #tagFilter li span {
        color: rgb(170, 170, 170);
        text-decoration: none;
        text-transform: capitalize;
        cursor: pointer;
    }

        #tagFilter li span:hover {
            color: rgb(64, 183, 184);
            outline-color: rgb(64, 183, 184);
            transition-delay: 0s;
            transition-duration: 0.25s;
            transition-property: color;
            transition-timing-function: ease;
        }

    #tagFilter li {
        display: inline-block;
        margin-left: 20px;
        margin-right: 0px;
    }

    #tagFilter ul {
        margin-bottom: 0;
        margin-left: 0;
        padding-left: 0;
    }

.gallery-loader, .gallery-noresults {
    margin-top: 90px;
    margin-bottom: 90px;
    text-align: center;
}

.gallery-loader span{
    margin-left:auto;
    margin-right: auto;
}

.gallery-loading-complete{
    text-align: center;
    margin-top: 30px;
    font-size: 15px;
}

