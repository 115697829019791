.photo-viewer img{
    max-height: calc(100vh - 160px);
}

.photo-viewer{
    text-align: center;
}

#photo-viewer-container #tagFilter{
    margin-bottom: 0;

}

.photo-loader, .photo-noresults {
    margin-top: 150px;
    margin-bottom: 90px;
    text-align: center;
}

    .photo-loader span {
        margin-left: auto;
        margin-right: auto;
    }
