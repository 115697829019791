
h1, h2, h3, h4, h5, h6, h1 span, h2 span, h3 span, h4 span, h5 span, h6 span, h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    font-family: Roboto;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-decoration: none !important;
    padding: 0;
    color: #222222;
    font-weight: 400;
}


    h1, h1 span, h1 a {
        font-size: 26px;
        line-height: 28px;
    }

    h2, h2 span, h2 a {
        font-size: 22px;
        line-height: 24px;
    }

    h3, h3 span, h3 a {
        font-size: 20px;
        line-height: 22px;
    }

    h5, h5 span, h5 a {
        font-size: 14px;
        line-height: 16px;
    }


body {
    background: #f7f7f7;
}

header {
    background: white;
}

* {
    font-family: PT Sans;
    font-weight: 400;
    color: #444444;
}

p, td, div, blockquote p, input {
    font-size: 14px;
    line-height: 22px;
}

footer {
    border-top: #e6e6e6 1px solid;
}

    footer .copyrights {
    }

        footer .copyrights p {
            font-size: 12px;
            line-height: 28px;
            margin-bottom: 5px;
            margin-top: 5px;
        }

        @media (min-width: 1250px) {
            footer .copyrights p.copyrights-short {
                display: none;
            }
        }

        @media (max-width: 1250px) {

            footer .copyrights p.copyrights-long {
                display: none;
            }
        }

.not-found {
    margin-top: 120px;
    margin-bottom: 200px;
}

    .not-found a {
        color: #444444;
        text-decoration: none;
    }

    .not-found .icon {
        font-size: 130px;
        line-height: 130px;
    }

    .not-found .text {
        font-size: 40px;
        line-height: 40px;
        margin-top: 10px;
    }

footer .social-links {
    padding-top: 5px;
}

    footer .social-links a {
        /*display: block;*/
        text-decoration: none;
        filter: grayscale(1);
        margin-right: 15px;
    }

        footer .social-links a img {
            margin-right: 5px;
            margin-top: -3px;
        }

        footer .social-links a:hover {
            text-decoration: none;
            filter: grayscale(0);
            font-weight: bold;
        }

        @media (max-width: 1000px) {

            footer .social-links span {
                display: none;
            }

            footer .social-links a img {
                margin-right: 0px;
            }
        }

/*remove lighbox shadows*/
.yarl__button {
     filter: initial;
}

    .yarl__button:hover {
        filter: invert(63%) sepia(42%) saturate(564%) hue-rotate(132deg) brightness(92%) contrast(89%);
        -webkit-font-smoothing: antialiased;
        outline-color: rgb(64, 183, 184);
        transition-delay: 0s;
        transition-duration: 0.25s;
        transition-property: filter;
        transition-timing-function: ease;
    }
