a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.profile-picture {
    display: inline-block;
}
.profile-picture img{
    width: 60px;
    height: 60px;
}

.slogan {
    margin-left: 10px;
    border-left-color: rgb(190, 190, 190);
    border-left-style: solid;
    border-left-width: 1px;
    color: rgb(67, 67, 67);
    display: inline-block;
    font-family: 'PT Sans';
    font-size: 12px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    vertical-align: top;
    margin-top: 10px;
}


.main-menu .menu-items a {
    color: rgb(54, 72, 72);
    display: inline;
    font-family: 'PT Sans';
    font-size: 13px;
    line-height: 15px;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    outline-color: rgb(52, 52, 52);
    outline-style: none;
    outline-width: 0px;
    text-decoration: none;
}

    .main-menu .menu-items a:hover {
        -webkit-font-smoothing: antialiased;
        color: rgb(64, 183, 184);
        outline-color: rgb(64, 183, 184);
        transition-delay: 0s;
        transition-duration: 0.25s;
        transition-property: color;
        transition-timing-function: ease;
    }

